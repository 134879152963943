
import Panorama from '../Aos/Panorama'
import imageUrl from '../img/aerial.jpg'
import image from '../img/robert-ritchie-WD_SESCascw-unsplash-1536x1151.jpg'
export default function Social () {
    return (
        <div>
            <div className='w-full'>
                <img className='w-full lg:h-96 bg-center' src={imageUrl} alt="" />
            </div>
            <div className='flex justify-between p-4 flex-wrap lg:px-48 lg:py-24 items-center bg-gray-50 ' >
                <div className='p-2 w-full lg:w-1/2 lg:px-24'>
                    <p className='text-black'>
                    Our <strong>company</strong> has redeveloped and planned a number of squares and gardens . it has also designed and  implemented the entrance to the city of kafr aldawar and prepared a plan for a service area and sports club.
                    </p>
                </div>
                <div className='p-2 w-full lg:w-1/2'>
                    <img className='w-full rounded-md' src={image} alt="" />
                </div>
            </div>
            <Panorama/>
        </div>
    )
}